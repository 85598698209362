var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stickerBackgroundStyle === "4"
    ? _c("div", { staticClass: "jagged-container" }, [
        _c("img", {
          staticClass: "event-sticker-image",
          style: _vm.eventStickerStyling,
          attrs: { src: _vm.stickerURL, role: "presentation" }
        }),
        _c("div", {
          staticClass: "inner-jagged",
          style: _vm.innerJaggedStyling
        }),
        _c("div", {
          staticClass: "inner-jagged-after",
          style: _vm.innerJaggedAfterStyling
        })
      ])
    : _vm.stickerBackgroundStyle === "3"
    ? _c("div", { staticClass: "wavy-container" }, [
        _c("img", {
          staticClass: "event-sticker-image",
          style: _vm.eventStickerStyling,
          attrs: { src: _vm.stickerURL, role: "presentation" }
        }),
        _c("div", { staticClass: "inner-wavy", style: _vm.innerWavyStyling }),
        _c("div", {
          staticClass: "inner-wavy-after",
          style: _vm.innerWavyAfterStyling
        })
      ])
    : _vm.stickerBackgroundStyle === "2"
    ? _c(
        "div",
        {
          staticClass: "straight-edge-container",
          style: _vm.straightEdgeStyling
        },
        [
          _c("img", {
            staticClass: "event-sticker-image",
            style: _vm.eventStickerStyling,
            attrs: { src: _vm.stickerURL, role: "presentation" }
          })
        ]
      )
    : _c("div", { staticStyle: { display: "inline-flex" } }, [
        _c("img", {
          staticClass: "event-sticker-image",
          style: _vm.eventStickerStyling,
          attrs: { src: _vm.stickerURL, role: "presentation" }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }