
import StickersServices from '@/services/stickers-services';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class EventStickerImage extends Vue {
  @Prop({ type: String, required: true, default: '' })
  stickerURL!: string;

  @Prop({ type: String, required: true, default: '1' })
  stickerBackgroundStyle!: string;

  @Prop({ type: String, required: true })
  stickerSize!: string;

  @Prop({ type: String, required: true })
  stickerBackgroundColor!: string;

  get eventStickerStyling() {
    return StickersServices.eventStickerStyling(this.stickerSize, this.stickerBackgroundStyle, this.stickerBackgroundColor);
  }

  get straightEdgeStyling() {
    return StickersServices.straightEdgeStyling(this.stickerBackgroundColor, this.stickerSize);
  }

  get innerJaggedStyling() {
    return StickersServices.innerJaggedStyling(this.stickerBackgroundColor);
  }

  get innerJaggedAfterStyling() {
    return StickersServices.innerJaggedAfterStyling(this.stickerBackgroundColor)
  }

  get innerWavyStyling() {
    return StickersServices.innerWavyStyling(this.stickerBackgroundColor);
  }

  get innerWavyAfterStyling() {
    return StickersServices.innerWavyAfterStyling(this.stickerBackgroundColor);
  }
}
